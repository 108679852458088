import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {Link, useNavigate, useParams} from "react-router-dom";
import Spiner from "../administrator/spiner/Spiner";
import './order.css';
import {
    changeDataAfterPayOrder,
    changeOrderStatus,
    changeStatusAfterPayOrder, dolyamiMethodCreate, findSertificate,
    getOneOrder, payOrderSertificate, yandexMethodCreate
} from "../../http/orderApi";
import {login} from "../../http/userAPI";
import {priceToUserString} from "../../http/basketApi";
import InputMask from "react-input-mask";
import {observer} from "mobx-react-lite";



const loadScript = (src, onLoad) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
    script.onload = onLoad;
};

function pay  (orderInfo) {
    var widget = new window.cp.CloudPayments();

    const basketItems = JSON.parse(orderInfo.BasketItems)
    const orderId = orderInfo.custom_order_id
    const price = orderInfo.result_price - orderInfo.paymentAmount
    const email = orderInfo.email
    const deliveryPrice = orderInfo.deliveryPrice

    let items = [];
    let basketPrice = 0;
    basketItems.forEach((oneItem) => {
        let itemPrice = oneItem.item.sell_price ? oneItem.item.sell_price : oneItem.item.product.price;
        let count = oneItem.count ? oneItem.count : 1;
        if (oneItem.mbPerformanceSeparatedFlag) {
            oneItem.mbPerformance.forEach((mbOneItem) => {
                items.push({
                    label: oneItem.item.name,
                    price: mbOneItem.discountedPriceOfLine / mbOneItem.quantity,
                    quantity: mbOneItem.quantity,
                    amount: mbOneItem.discountedPriceOfLine,
                    vat: null
                });
                basketPrice += mbOneItem.discountedPriceOfLine;
            });
        } else {
            items.push({
                label: oneItem.item.name,
                price: itemPrice,
                quantity: count,
                amount: count * itemPrice,
                vat: null
            });
            basketPrice += itemPrice;
        }
    })

    if ((basketPrice + deliveryPrice) !== price) {
        items = [];
        items.push({
            label: 'Заказ товара YOU WANNA',
            price: price - deliveryPrice,
            quantity: 1,
            amount: price - deliveryPrice,
            vat: null
        })
    }

    if (deliveryPrice > 0) {
        items.push({
            label: 'Доставка',
            price: deliveryPrice,
            quantity: 1,
            amount: deliveryPrice,
            vat: null
        })
    }

    let receipt = {
        Items: items,
        email: email, //e-mail покупателя, если нужно отправить письмо с чеком
        amounts:
            {
                electronic: price, // Сумма оплаты электронными деньгами
                advancePayment: 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                credit: 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
                provision: 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
            }
    };

    let data = {
        custom_order_id: orderId
    };
    data.CloudPayments = {
        CustomerReceipt: receipt // Чек
    }

    widget.pay('auth', // или 'charge'
        { //options
            publicId: 'pk_c94aafd49929bd3556a26c8b60e93', //id из личного кабинета
            description: 'Оплата товаров в YOU-WANNA', //назначение
            amount: price, //сумма
            currency: 'RUB', //валюта
            accountId: email, //идентификатор плательщика (необязательно)
            invoiceId: orderId, //номер заказа  (необязательно)
            email: email, //email плательщика (необязательно)
            skin: "mini", //дизайн виджета (необязательно)
            data: data,
            configuration: {
                paymentMethodsOrder: [
                    window.cp.constant.ExternalPaymentMethods.MirPay,
                    window.cp.constant.ExternalPaymentMethods.SbpPay,
                    window.cp.constant.ExternalPaymentMethods.TinkoffInstallmentPay
                ]
            }
        },
        {

            onSuccess: function (options) { // success
                window.location.reload();
            },
            onFail: function (reason, options) { // fail
                console.log(reason, 'fail')
            },
            onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                //например вызов вашей аналитики Facebook Pixel
                //Допилить нормальные проверки на фронте, доделать функцию отдельную от //order// админа.
                //Доделать тригеры реагирующие на смену статуса заказа.
                if(paymentResult.success) {
                    changeDataAfterPayOrder(options).then(responsePay => {
                        if(responsePay) {
                            window.location.reload();
                        }
                    })

                }

            }
        }
    )
}

function floctoryPush(response) {
    let basketItems = JSON.parse(response.BasketItems);
    let username = response.name;
    let userEmail = response.email;
    let orderIdForFloctery = response.custom_order_id;

    let items = [];

    basketItems.forEach((oneItem) => {
        items.push({
            id: oneItem.child_product_id,
            title:oneItem.item.name,
            price:oneItem.item.sell_price ? oneItem.item.sell_price : oneItem.item.product.price,
            count: oneItem.count,
            image: process.env.REACT_APP_API_URL+"uploads/images/"+oneItem.item.product.id+"/"+oneItem.item.id+"/"+oneItem.item.img_preview,
        })
    })
}




const Order_confirm = observer(() => {
    const Navigate = useNavigate();
    const {settings,user} = useContext(Context);
    const params = useParams();
    const [orderInfo, setOrderInfo] = useState(null);
    const [currentPaymentMethod,setCurrentPaymentMethod] = useState();
    const [userInfoConfirm,setUserInfoConfirm] = useState(null);
    const [sertificateModalOpen, setSertificateModalOpen] = useState(false);
    const [btnTextState,setBtnTextState] = useState(false)
    const [dolyamiLink,setDolyamiLink] = useState('');
    const [dolyamiStatus, setDolyamiStatus] = useState('');
    const [yandexLink,setYandexLink] = useState('');
    const [yandexStatus, setYandexStatus] = useState('');
    const [showPreloader, setShowPreloader] = useState(false);
    const [certificates, setCertificates] = useState([{id: 1, value: '', isActive: false, message: ''}])
    const [certsAmount, setCertsAmount] = useState(0);
    const [firstClickPayCert, setFirstClickPayCert] = useState(true);
    const [certPrepayMessage, setCertPrepayMessage] = useState('');

    useEffect(() => {
        if(showPreloader && dolyamiLink){
            setShowPreloader(false)
            window.open(dolyamiLink, '_blank')
        }
    }, [showPreloader, dolyamiLink])

    useEffect(() => {
        if(showPreloader && yandexLink){
            setShowPreloader(false)
            window.open(yandexLink, '_blank')
        }
    }, [showPreloader, yandexLink])

    useEffect(() => {
        setFirstClickPayCert(true);
    }, [certificates])

    useEffect(() => {
        if(!user.shadowOverlay){
            setSertificateModalOpen(false)
        }
    },[user.shadowOverlay])

    useEffect(() => {
        window.scrollTo(0, 0)

        if(!params.custom_order_id) {

            return false;
        }

        getOneOrder(params.custom_order_id).then(response => {


            if(!response) {
                Navigate("/404");
            }

            //google аналитика
            window.dataLayer.push({ 'event': 'Complete',
                'event_category': 'Order_complete',
                'event_label': params.custom_order_id

            });

                setUserInfoConfirm({name:response.name,email:response.email, surname: response.surname})

                setOrderInfo(response)

            floctoryPush(response)

            setCurrentPaymentMethod(response.paymentmethod)


            if(response.paymentmethod.code === "payment_online" && response.paymentAmount < response.result_price && !response.TransactionId)   {
                loadScript("https://widget.cloudpayments.ru/bundles/cloudpayments.js", () => {
                    pay(response)
                });
            }

            if(response.paymentmethod.code === "sertificate" && response.paymentAmount < response.result_price && !response.TransactionId)   {
                setSertificateModalOpen(true);
                document.body.style.overflowY = "scroll";
                user.setShadowOverlay(true)
            }


            if (response.paymentmethod.code === "dolyami") {

                if(response.dolyami_link) {
                    setDolyamiLink(response.dolyami_link)
                }
                setDolyamiStatus(response.dolyami_status);

                dolyamiMethodCreate(response).then(responseDoly => {
                    if(responseDoly.link) {
                        if(!response.dolyami_link) {
                            setDolyamiLink(responseDoly.link)
                        }
                        setShowPreloader(false)
                    } else {
                        setDolyamiStatus("canceled");
                        setShowPreloader(false)
                    }
                })
            }

            if (response.paymentmethod.code === "ysplit") {
                yandexMethodCreate(response).then(responseYandex => {
                    console.log('responseYandex', responseYandex);
                    if (responseYandex?.response?.data?.paymentUrl) {
                        setYandexStatus(responseYandex.response.status);
                        setYandexLink(responseYandex.response.data.paymentUrl);
                        setShowPreloader(false);
                    } else {
                        setYandexStatus("canceled");
                        setShowPreloader(false);
                    }
                })
            }

        })

    },[Navigate, params.custom_order_id, user])


const checkSertificateSuccessHandler = (id, sertificateCode, orderInfo) => {
        setShowPreloader(true)
        findSertificate(sertificateCode, orderInfo).then(response => {
            setShowPreloader(false)
            if(response.error){
                const newCerts = certificates.map(item => {
                    if (item.id === id) {
                        return {...item, message: response.error};
                    }
            
                    return item;
                })
                setCertificates(newCerts);
                return false;
            }
            if(response.message && response.amount) {
                let newCert = false;
                const newCerts = certificates.map(item => {
                    if (item.id === id && !item.isActive) {
                        newCert = true;
                        return {...item, isActive: true, message: response.message};
                    }
            
                    return item;
                })
                setBtnTextState(true);
                if (newCert) {
                    if (!newCerts.find(item => !item.isActive)) {
                        addCert(newCerts);
                    }
                    setCertsAmount(certsAmount + response.amount);
                }
            }
    })
}

const handleCertInputChange = (value, id) => {
    if (value && certificates.find(item => item.value === value)) {
        const newCerts = certificates.map(item => {
            if (item.id === id) {
                return {...item, value: '', message: 'Нельзя использовать один сертификат дважды'};
            }
    
            return item;
        })
        setCertificates(newCerts);
        return false;
    }
    const newCerts = certificates.map(item => {
        if (item.id === id && !item.isActive) {
            return {...item, isActive:false, value};
        }

        return item;
    })
    setCertificates(newCerts);
}

const addCert = (newCerts) => {
    if (certificates.length < 10) {
        let maxId = newCerts.reduce((acc, curr) => acc.id > curr.id ? acc : curr).id;
        newCerts.push({
            id: maxId + 1,
            isActive: false,
            value: '',
            message: ''
        });
        setCertificates(newCerts);
    }
}

const paySertificateHandler = (orderInfo) => {
    if (certsAmount) {
        if (firstClickPayCert) {
            if (certsAmount >= orderInfo.result_price) {
                setCertPrepayMessage(`Применены сертификаты на сумму ${priceToUserString(certsAmount)} ₽. Они полностью покрывают сумму заказа. Чтобы продолжить, нажмите "Оплатить" еще раз`)
            } else {
                setCertPrepayMessage(`Применены сертификаты на сумму ${priceToUserString(certsAmount)} ₽. Вам останется доплатить ${priceToUserString(orderInfo.result_price - certsAmount)} ₽. Чтобы продолжить, нажмите "Оплатить" еще раз`)
            }
            setFirstClickPayCert(false);
        } else {
            setShowPreloader(true)
            const certs = certificates.filter(item => item.value && item.isActive)
            payOrderSertificate(certs, orderInfo).then(response => {
                if(response.allpay || response.prepay){
                    window.location.reload()
                } else {
                    setCertificates(response)
                }
                setShowPreloader(false)
                setFirstClickPayCert(true);
                setCertsAmount(0);
                setCertPrepayMessage('');
                return false;
            })
        }
    }
    
}

const closeModalSertificat = () => {
    user.setShadowOverlay(false)
    setSertificateModalOpen(false)
}

    return (
        <div style={{marginTop: settings.headerHeight}} className={"page_order_hm"}>
            <div className={"wrapper_page_order"}>
                { !orderInfo || showPreloader ?
                    <Spiner />
                    :
                    <div className={"order_notificator"}>
                        {
                            sertificateModalOpen ?
                                <div className={"pay_sertificate_modal"}>
                                    <div
                                        onClick={e=> closeModalSertificat()}
                                        className={"pay_sert_close"}>
                                        <div className={'pay_sert_close_child'}></div>
                                    </div>
                                    <div className={"pay_sertificate_modal_head"}>
                                        <span>Оплата товаров в YOU-WANNA</span>
                                        <span>{priceToUserString(orderInfo.result_price)+" ₽"}</span>
                                    </div>
                                    <div  className={"wrapper_for_sertificate_pay_input"}>
                                    {
                                        certificates.map(certInput => {
                                            return (
                                                <>
                                                        <span className={"notif_pay_input"}>Введите код сертификата </span>
                                                        <div key={certInput.id} className={"support_pay_input"}>
                                                            <input
                                                                className={certInput.isActive ? 'user_email_component success_cert' : 'user_email_component'}
                                                                value={certInput.value}
                                                                disabled={certInput.isActive}
                                                                onChange={e => handleCertInputChange(e.target.value, certInput.id)}
                                                                type="text"
                                                            />
                                                            <div
                                                                onClick={e => checkSertificateSuccessHandler(certInput.id, certInput.value, orderInfo)}
                                                                className={"confirm_sertificate_button"}>Активировать
                                                            </div>
                                                                
                                                        </div>
                                                    <div className={"notification_sertif_pay_text"}>{certInput.message}</div>
                                                </>
                                            )
                                        })

                                    }
                                    </div>

                                    {
                                        !!certPrepayMessage && 
                                        <div className='certificate_prepay_message'>
                                            {certPrepayMessage}
                                        </div>
                                    }
                                    {
                                        btnTextState &&
                                        <div
                                            onClick={e => paySertificateHandler(orderInfo)}
                                            className={"pay_sertificate_button"}>{firstClickPayCert ? 'Применить' : 'Оплатить'}
                                        </div>
                                    }
                                    

                                </div>
                                : ""
                        }

                        <div className={"circle_success"}></div>
                        <span className={"thank_you_for_order"}>Спасибо за заказ!</span>
                        <div className={"thank_text_container"}>
                            <span className={"thank_text_child"}>Ваш заказ успешно оформлен. </span>
                            <span className={"thank_text_child"}>Номер вашего заказа №{orderInfo.custom_order_id}.</span>
                            <span className={"thank_text_child"}>В ближайшее время с вами свяжется менеджер.</span>
                        </div>
                        {
                            currentPaymentMethod && currentPaymentMethod.code === "payment_online" && orderInfo.paymentAmount < orderInfo.result_price  && !orderInfo.TransactionId   ?
                                <div

                                    onClick={e =>  pay(orderInfo)}
                                    className={"payment_button_order_page"}> Оплатить заказ </div>
                                : ""
                        }

                        {
                            currentPaymentMethod && currentPaymentMethod.code === "dolyami" && orderInfo.paymentAmount < orderInfo.result_price && ( dolyamiStatus !== "canceled" && dolyamiStatus !== "rejected")  ?

                                <a className={"payment_button_order_page"} onClick={() => setShowPreloader(true)}   target="_blank" rel="noopener noreferrer" > Оплатить долями </a>

                                : ""
                        }
                        {
                            currentPaymentMethod && currentPaymentMethod.code === "dolyami" && ( dolyamiStatus === "canceled" || dolyamiStatus === "rejected")   ?
                                <div className={"confirm_page_order_pay_notif_fail"}>
                                    В оплате Долями отказано, либо заказ отменен.
                                </div> : ""

                        }

                        {
                            currentPaymentMethod && currentPaymentMethod.code === "ysplit"
                            && orderInfo.paymentAmount < orderInfo.result_price && (yandexStatus === "created" || yandexStatus === 'success') ?

                                <a className={"payment_button_order_page"} onClick={() => setShowPreloader(true)}   target="_blank" rel="noopener noreferrer" > Оплатить Yandex Split </a>

                                : ""
                        }
                        {
                            currentPaymentMethod && currentPaymentMethod.code === "ysplit" && yandexStatus === "fail" ?
                                <div className={"confirm_page_order_pay_notif_fail"}>
                                    В оплате Yandex Split отказано, либо заказ отменен.
                                </div> : ""
                        }



                        <div className={orderInfo.paymentAmount === orderInfo.result_price ? "confirm_page_order_pay_notif active" : "confirm_page_order_pay_notif"}>
                            {
                                orderInfo.paymentAmount === orderInfo.result_price ?
                                    "Ваш заказ оплачен."
                                    : ""
                            }
                        </div>

                        <Link  to={"/catalog/"} className={currentPaymentMethod && currentPaymentMethod.code === "payment_online" && orderInfo.paymentAmount < orderInfo.result_price ? "order_page_go_catalog invers" : "order_page_go_catalog"}>
                            Продолжить покупки
                        </Link>

                    </div>

                }

            </div>
        </div>
    );
});

export default Order_confirm;
