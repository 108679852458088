
import {$host} from "./index";

export const addToBasket = async (item,userId,currentSize) => {
    const {data} = await $host.post('api/basket/addproducttobasket',{item,userId,currentSize})
    return data;
}

export const getAndMergeBasketItems = async (id,basketItems) => {
    const {data} = await $host.post('api/basket/getandmergebasketitems', {id,basketItems})
    return data;
}

export const mergeBasketItemsNoUser = async (basketItems) => {
    const {data} = await $host.post('api/basket/mergebasketitems', {basketItems})
    return data;
}

export const updateCountItemsBasket = async (modifier, item, userId) => {
    if(item.count === 1 && modifier === "decrease") {
        return false;
    }

    const {data} = await $host.post('api/basket/updatecountitems',{modifier,item,userId})

    return data;
}

export const deleteBaseBasketOneItem = async (item,userId) => {

    const {data} = await $host.post('api/basket/deleteoneitembasket', {item,userId})

    return data;

}

export const deleteAllBasketItems = async (items, userId) => {
    const {data} = await $host.post('/api/basket/clearallbasketitems',{items, userId})

    return  data;
}

export const helpFindCity = async (cityName) => {
    const {data} = await $host.post('/api/basket/supportfindcity',{cityName})

    return  data;
}

export const findDeliveryForOrder = async (item, giftOnly) => {
    const {data} = await $host.post('/api/basket/finddeliveryfororder', {item, giftOnly})

    return data;
}

export const sdekGetCityOffice = async (cityInfo) => {
    const {data} = await $host.post("/api/basket/sdekgetcity",{cityInfo});
    return data;
}

export const yandexGetCityOffice = async (cityInfo) => {
    const {data} = await $host.post("/api/basket/yandexgetcity",{cityInfo});
    return data;
}

export const yandexGetCostToOffice = async (office_id, priceNoSell) => {
    const {data} = await $host.post("/api/basket/yandexgetcost", {office_id, priceNoSell});
    console.log('data', data)
    return data;
}

export const getDeliveryAddress = async (city, value) => {
    const {data} = await $host.post('/api/basket/getdeliveryaddress', {city, value});
    return data;
}

export const getDeliveryToDoorPrice = async (value, type, priceNoSell) => {
    const {data} = await  $host.post('/api/basket/gettodoorprice',{value, type, priceNoSell});
    return data;
}

export const getPaymentMethodsById = async (idArray) => {
    const {data} = await $host.post('/api/basket/getpaymentsbyid', {idArray})
    return data;
}

export const searchPromocodeAndActive = async (promocode,user, basketSumm) => {
    const {data} = await $host.post('/api/basket/searchpromocode',{promocode,user, basketSumm})
    return  data;
}

//#####################     LocalStorage Basket Function    #####################//
export const priceToUserString = (price) => {
    let stringPrice = String(price);
    return stringPrice.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
}

export const isUsePromocode = (item, categoryArray, productsArray) => {
    let result = true;
    let categoriesArrayOneProduct = item.item.product.product_category;
    if(categoryArray.length) {
        for(let oneCategoryProduct of categoriesArrayOneProduct) {
            if (!categoryArray.find(element => element.id === oneCategoryProduct)) {
                result = false;
            } else {
                result = true;
                break;
            }
        }
    }

    if(result === false) {
        //не получил скидки по категории.
    }

    if(productsArray.length && result) {
        if (!productsArray.find(element => element.id === item.item.id)) {
            result = false;
        }
    }
    return result;
}

export const calculateBasket = async (items, delivery, delPrice, promo, payment, user, bonusesAmount) => {
    try {
        const {data} = await $host.post('/api/basket/calculatebasket', {items, delivery, delPrice, promo, payment, user, bonusesAmount})
        return data;
    } catch(error) {
        return null
    }
}

export const checkBasketItems = async (items) => {
    const {data} = await $host.post('api/basket/checkbasketitems', {items})
    return data;
}

export const mindboxSetCart = async (deviceUUID, userAgent, productList) => {
    const {data} = await $host.post('api/basket/mindboxsetcart',{deviceUUID, userAgent, productList})
    return data;
}

export const mindboxGetRecommend = async (deviceUUID, userAgent) => {
    const {data} = await $host.post('api/basket/mindboxgetrecommend',{deviceUUID, userAgent})
    return data;
}