import React from 'react'

const Heart = () => {
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3984 3.4721L11.9308 4.00767L12.4627 3.4715L13.4058 2.52081C13.4058 2.52079 13.4058 2.52076 13.4058 2.52073C15.5305 0.383687 18.9681 0.383715 21.0927 2.52081C23.2145 4.65519 23.213 8.10839 21.0934 10.2529C21.0932 10.2532 21.093 10.2534 21.0928 10.2536L12.0095 19.385L12.0094 19.3851C11.9658 19.4289 11.8971 19.4289 11.8535 19.3851L2.77559 10.2538C2.77559 10.2538 2.77559 10.2538 2.77559 10.2538C0.652925 8.11864 0.652934 4.65509 2.77559 2.51996L2.77559 2.51996C4.89582 0.387247 8.33157 0.387254 10.4517 2.51995L10.4518 2.51997L11.3984 3.4721Z" stroke="black" stroke-width="1.5"/>
</svg>


  )
}

export default Heart